const slugify = require("slugify")

const slugifyParams = {
  replacement: "-", // replace spaces with replacement character, defaults to `-`
  remove: undefined, // remove characters that match regex, defaults to `undefined`
  lower: true, // convert to lower case, defaults to `false`
  strict: true, // strip special characters except replacement, defaults to `false`
  locale: "fr", // language code of the locale to use
}

exports.forSchool = school => {
  const components = []
  const removeParenthesisRegex = /\(ex .*\)/g
  components.push(school.nom.replace(removeParenthesisRegex, ""))
  if (
    slugify(components.join(" "), slugifyParams).includes(
      slugify(school.commune, slugifyParams)
    )
  ) {
    return slugify(components.join(" "), slugifyParams)
  }

  components.push(school.commune)
  return slugify(components.join(" "), slugifyParams)
}

exports.forString = (key, value) =>
  `/${slugify(key, slugifyParams)}/${slugify(value, slugifyParams)}`
