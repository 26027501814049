import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"

const List = styled.ul`
  display: inline;
  margin-left: 0px;
`
const Item = styled.li`
  margin: 5px;
  display: inline-block;
`

export const Social = ({ profiles }) => {
  Object.keys(profiles).forEach(k => !profiles[k] && delete profiles[k])
  if (Object.keys(profiles).length === 0) {
    return null
  }
  return (
    <>
      <span>Réseaux sociaux:</span>
      <List>
        {Object.keys(profiles).map(network => (
          <Item key={network}>
            <a href={profiles[network]}>{network}</a>
          </Item>
        ))}
      </List>
    </>
  )
}

Social.propTypes = {
  profiles: PropTypes.object.isRequired,
}
