import React from "react"
import Map from "pigeon-maps"
import { Link } from "gatsby"
import { forString } from "../utils/slugs"
import { schoolType } from "../utils/schoolType"
import Layout from "../components/layout"
import SEO from "../components/seo"
import { Wikipedia } from "../components/wikipedia"
import { Social } from "../components/social"
import mapTilerProvider from "../utils/mapTileProvider"

const schoolTemplate = props => {
  const { pageContext } = props
  const { school, extra } = pageContext

  console.log(pageContext)

  let dependency = <></>
  if (school.universite.length > 0) {
    dependency = (
      <>
        qui dépend de{" "}
        {school.universite
          .map(name => (
            <Link key={name} to={forString("universite", name)}>
              {name}
            </Link>
          ))
          .reduce((prev, curr) => [prev, ", ", curr])}
      </>
    )
  }

  let tutelles = <></>
  if (school.tutelle.length > 0) {
    tutelles = (
      <>
        sous la tutelle du{" "}
        {school.tutelle.reduce((prev, curr) => [prev, ", ", curr])}
      </>
    )
  }

  let academia = <></>
  if (school.academie && school.academie !== school.commune) {
    academia = (
      <>
        {" "}
        (académie de{" "}
        <Link to={forString("academie", school.academie)}>
          {school.academie}
        </Link>
        )
      </>
    )
  }

  const description = `${school.nom} est ${schoolType(
    school.type_detablissement
  )} ${school.statut.toLowerCase()} situé a ${school.commune}.`

  return (
    <Layout>
      <SEO lang="fr" description={description} title="Etudiant A" />

      <h1>{school.nom}</h1>
      <h2>{school.sigle}</h2>
      <p>
        <em>{school.nom}</em>
        {extra?.url && <a href={extra.url}>🔗</a>} est{" "}
        {schoolType(school.type_detablissement)} {school.statut.toLowerCase()}{" "}
        situé a{" "}
        <Link to={forString("commune", school.commune)}>{school.commune}</Link>
        {academia} {dependency} {tutelles}.
      </p>

      {extra?.wikipedia?.extract && <Wikipedia {...extra.wikipedia} />}

      <Social
        profiles={{
          facebook: extra?.compte_facebook,
          twitter: extra?.compte_twitter,
          youtube: extra?.compte_youtube,
        }}
      />

      <Map
        center={[school.latitude_y, school.longitude_x]}
        provider={mapTilerProvider}
        zoom={16}
        height={400}
      />

      <ul>
        {[
          "commune",
          "departement",
          "region",
          "universite",
          "type_detablissement",
          "tutelle",
          "academie",
        ].map(key => {
          if (Array.isArray(school[key])) {
            return school[key].map(value => {
              const path = forString(key, value)
              return (
                <li key={[key, value]}>
                  <Link to={path}>{value}</Link>
                </li>
              )
            })
          } else {
            const value = school[key]
            const path = forString(key, value)
            return (
              <li key={[key, value]}>
                <Link to={path}>{value}</Link>
              </li>
            )
          }
        })}
      </ul>
    </Layout>
  )
}
export default schoolTemplate
