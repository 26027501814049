export const schoolType = types => {
  return types
    .map(type => {
      if (type === "Autre établissement du supérieur") {
        return "un établissement d'enseignement supérieur"
      }
      return type
    })
    .join(", ")
}
